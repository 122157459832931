// eslint-disable-next-line max-classes-per-file
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  provideIntlService,
  registerForIntl,
} from "@progress/kendo-react-intl";
import PropTypes from "prop-types";
import { Component, createRef } from "react";
import dayjs from "dayjs";
import toastService from "../../utils/toastService";
import GridComboBox from "./GridComboBox";
import GridDropDownTree from "./GridDropDownTree";

function orderBy(data, sort) {
  const res = [...data];
  switch (sort.type) {
    case "number":
      res.sort((a, b) => {
        if (a[sort.field] === null) return -1;
        if (b[sort.field] === null) return 1;
        return Number(a[sort.field]) - Number(b[sort.field]);
      });
      break;
    case "string":
      res.sort((a, b) => {
        if (a[sort.field] === null) return -1;
        if (b[sort.field] === null) return 1;
        const fieldA = a[sort.field].toLowerCase();
        const fieldB = b[sort.field].toLowerCase();
        if (fieldA < fieldB) return -1;
        if (fieldA > fieldB) return 1;
        return 0;
      });
      break;

    case "date":
      res.sort((a, b) => {
        if (a[sort.field] === null) return -1;
        if (b[sort.field] === null) return 1;
        const dateA = dayjs(a[sort.field], sort.format).toDate();
        const dateB = dayjs(b[sort.field], sort.format).toDate();
        return dateA - dateB;
      });
      break;

    default:
      break;
  }
  if (sort.dir === "desc") res.reverse();
  return res;
}

class GridInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value,
      });
    }
  }

  onChange = ({ value }) => {
    this.setState({ value });
  };

  onBlur = () => {
    const { onChange, value: propsValue } = this.props;
    const { value } = this.state;
    if (propsValue !== value) {
      onChange({ value });
    }
  };

  render = () => {
    const { type, errors, format } = this.props;
    const { value } = this.state;
    switch (type) {
      case "text":
        return (
          <Input
            {...this.props}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            className="k-numerictextbox-align-right"
          />
        );
      default:
        return (
          <NumericTextBox
            className="k-numerictextbox-align-right"
            {...this.props}
            value={value}
            valid={!errors}
            onChange={this.onChange}
            format={format}
            onBlur={this.onBlur}
            min={0}
          />
        );
    }
  };
}

class CustomGridCell extends Component {
  render() {
    const {
      dataIndex,
      columnIndex,
      dataItem,
      field,
      editIndex,
      editable,
      column,
      errors,
      disabled,
      itemChange,
      toggleDialog,
      changeEditIndex,
    } = this.props;
    const tabIndex = dataIndex * 10 + columnIndex;
    const inputError = errors.find((error) => {
      return error.index === dataIndex && error.field === field;
    });
    if (dataItem.gridIndex === editIndex && editable) {
      let template;
      switch (column.type) {
        case "checkbox":
          template = (
            <Checkbox
              key={`checkbox-${tabIndex}`}
              tabIndex={tabIndex}
              value={!!dataItem[field]}
              onChange={({ value }) => {
                itemChange(
                  {
                    dataItem,
                    field,
                    value,
                  },
                  column.afterChange
                );
              }}
            />
          );
          break;
        case "text":
          template = (
            <GridInput
              key={`text-${tabIndex}`}
              type="text"
              tabIndex={tabIndex}
              value={dataItem[field]}
              onChange={({ value }) => {
                itemChange(
                  {
                    dataItem,
                    field,
                    value,
                  },
                  column.afterChange
                );
              }}
            />
          );
          break;
        case "sorting":
          template = (
            <>
              {column.sorting.isUpShown(dataItem) === false ? (
                <div style={{ flex: 1 }} />
              ) : (
                <Button
                  style={{ flex: 1 }}
                  type="button"
                  title="monter"
                  icon="chevron-up"
                  look="flat"
                  onClick={() => {
                    column.sorting.onMoveClick(dataItem.gridIndex, -1);
                    changeEditIndex(-1);
                  }}
                />
              )}
              {column.sorting.isDownShown(dataItem) === false ? (
                <div style={{ flex: 1 }} />
              ) : (
                <Button
                  type="button"
                  title="monter"
                  icon="chevron-down"
                  look="flat"
                  onClick={() => {
                    column.sorting.onMoveClick(dataItem.gridIndex, 1);
                    changeEditIndex(1);
                  }}
                />
              )}
            </>
          );
          break;
        case "delete":
          template = (
            <Button
              key={`btn-delete-${tabIndex}`}
              tabIndex={tabIndex}
              type="button"
              title="Supprimer la ligne"
              icon="delete"
              look="flat"
              onClick={toggleDialog}
              hidden={dataItem.typeCoop === "Domaine"}
            />
          );
          break;
        case "dropdowntree":
          template = (
            <GridDropDownTree
              list={column.dropdowntree.list}
              tabIndex={tabIndex}
              textField={column.dropdowntree.textField}
              value={column.dropdowntree.value(dataItem).text}
              canAdd={column.dropdowntree.canAdd}
              onClickAdd={column.dropdowntree.onClickAdd}
              onChange={({ value }) => {
                if (!!value) {
                  itemChange(
                    {
                      dataItem,
                      value: column.dropdowntree.updateItem(dataItem, value),
                    },
                    column.afterChange
                  );
                }
              }}
              dataItemKey={column.dropdowntree.dataItemKey}
              groupKey={column.dropdowntree.groupKey}
            />
          );
          break;
        case "date":
          template = (
            <div className="k-d-flex">
              <DatePicker
                tabIndex={tabIndex}
                value={column.date.value(dataItem)}
                onChange={({ value }) => {
                  if (!!value) {
                    itemChange(
                      {
                        dataItem,
                        value: column.date.updateItem(dataItem, value),
                      },
                      column.afterChange
                    );
                  }
                }}
              />
              {column.date.onClear && (
                <Button
                  type="button"
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    itemChange(
                      {
                        dataItem,
                        value:
                          column.date.onClear && column.date.onClear(dataItem),
                      },
                      column.afterChange
                    );
                  }}
                />
              )}
            </div>
          );
          break;
        case "combobox":
          template = (
            <GridComboBox
              key={`combobox-${tabIndex}`}
              tabIndex={tabIndex}
              list={column.combobox.list}
              textField={column.combobox.textField}
              value={column.combobox.value(dataItem)}
              canAdd={column.combobox.canAdd}
              onClickAdd={column.combobox.onClickAdd}
              onChange={({ value }) => {
                if (!!value) {
                  itemChange(
                    {
                      dataItem,
                      value: column.combobox.updateItem(dataItem, value),
                    },
                    column.afterChange
                  );
                }
              }}
              dataItemKey={column.combobox.dataItemKey}
            />
          );
          break;
        case "actions":
          template = (
            <div>
              {column.actions.map(({ title, icon, action }) => (
                <Button
                  tabIndex={tabIndex}
                  key={title}
                  icon={icon}
                  title={title}
                  onClick={() => action(dataItem)}
                />
              ))}
            </div>
          );
          break;
        default:
          template = (
            <GridInput
              key={`numeric-${tabIndex}`}
              tabIndex={tabIndex}
              precision={column.precision ?? 0}
              type="tel"
              errors={inputError}
              field={field}
              value={
                column.computedValue
                  ? column.computedValue(dataItem)
                  : dataItem[field]
              }
              onChange={({ value }) =>
                itemChange(
                  {
                    dataItem,
                    field,
                    value,
                  },
                  column.afterChange
                )
              }
              {...column.fieldProps}
            />
          );
      }
      return (
        <td
          key={tabIndex}
          style={{
            textAlign: "right",
          }}
          className={`${column.editCellClassName}`}
        >
          {template}
        </td>
      );
    }
    let template;
    switch (column.type) {
      case "checkbox":
        template = (
          <Checkbox
            key={`checkbox-${tabIndex}`}
            disabled
            tabIndex={tabIndex}
            value={!!dataItem[field]}
            onChange={({ value }) => {
              itemChange(
                {
                  dataItem,
                  field,
                  value,
                },
                column.afterChange
              );
            }}
          />
        );
        break;
      case "number": {
        let val = column.computedValue
          ? column.computedValue(dataItem)
          : dataItem[field];
        val = val !== "" && val !== null ? parseFloat(val) : null;
        val = column.format
          ? provideIntlService(this).formatNumber(val, column.format)
          : val;
        template =
          column.precision && val !== null
            ? val.toFixed(column.precision)
            : val;
        break;
      }
      case "actions":
        template = (
          <>
            {column.actions.map((value) => {
              const show =
                value.show !== undefined ? value.show(dataItem) : true;
              return (
                value != null &&
                value.title != null &&
                show && (
                  <Button
                    tabIndex={tabIndex}
                    key={value.title}
                    icon={value.icon}
                    title={value.title}
                    onClick={() => value.action(dataItem)}
                  />
                )
              );
            })}
          </>
        );
        break;
      case "list": {
        const list =
          typeof column.list === "function" ? column.list() : column.list;
        template = list.find(column.findPredicate(dataItem))[column.textField];
        break;
      }
      case "sorting":
        template = (
          <>
            {column.sorting.isUpShown(dataItem) === false ? (
              <div style={{ flex: 1 }} />
            ) : (
              <Button
                disabled
                style={{ flex: 1 }}
                type="button"
                title="monter"
                icon="chevron-up"
                look="flat"
                onClick={() =>
                  column.sorting.onMoveClick(dataItem.gridIndex, -1)
                }
              />
            )}
            {column.sorting.isDownShown(dataItem) === false ? (
              <div style={{ flex: 1 }} />
            ) : (
              <Button
                disabled
                type="button"
                title="monter"
                icon="chevron-down"
                look="flat"
                onClick={() =>
                  column.sorting.onMoveClick(dataItem.gridIndex, 1)
                }
              />
            )}
          </>
        );
        break;
      case "delete":
        template = (
          <Button
            key={`btn-delete-${tabIndex}`}
            tabIndex={tabIndex}
            disabled
            type="button"
            title="Supprimer la ligne"
            icon="delete"
            look="flat"
            onClick={toggleDialog}
            hidden={dataItem.typeCoop === "Domaine"}
          />
        );
        break;
      case "dateFinDA":
        template = dayjs(dataItem[field]).format("YYYY-MM");
        break;
      default:
        template = column.computedValue
          ? column.computedValue(dataItem)
          : dataItem[field];
    }
    return (
      <td
        key={tabIndex}
        className={`${column.cellClassName} k-text-right ${
          column.type !== "actions" && disabled && "k-state-disabled"
        } ${inputError && "input-error "}`}
      >
        {template}
      </td>
    );
  }
}

class CustomGrid extends Component {
  constructor(props) {
    super(props);
    this.gridRef = createRef();
    this.scrollElement = createRef();
    this.state = {
      editIndex: null,
      useMinWidth: false,
      dialogIsOpen: false,
      containerWidth: 0,
    };
  }

  componentDidMount() {
    const { columns } = this.props;
    let useMinWidth;
    let containerWidth;
    this.resizeObserver = new ResizeObserver((entries) => {
      entries
        .filter((entry) => !!entry.contentBoxSize)
        .forEach((entry) => {
          // Chrome & Firefox implements contentBoxSize differently, integrating both
          const contentBoxSize = Array.isArray(entry.contentBoxSize)
            ? entry.contentBoxSize[0]
            : entry.contentBoxSize;
          containerWidth = contentBoxSize.inlineSize;
          const totalColumnsSize = columns
            .map((c) => c.width)
            .reduce((acc, curr) => acc + (Number(curr) || 0), 0);

          useMinWidth = containerWidth <= totalColumnsSize;
        });
      this.setState(
        {
          useMinWidth,
          containerWidth,
        },
        () => this.forceUpdate()
      );
    });
    this.resizeObserver.observe(this.gridRef.current.element.parentElement);
    this.scrollElement.current = this.gridRef.current.element.querySelector(
      ".k-grid-content.k-virtual-content"
    );
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.gridRef.current.element.parentElement);
  }

  GridCell = (props) => {
    const { columns, errors, canEdit, disabled } = this.props;
    const { editIndex } = this.state;
    const column = columns[props.columnIndex];
    const columnEditable =
      canEdit &&
      (typeof column.editable === "function" ? column.editable() : true);
    return (
      <CustomGridCell
        {...props}
        column={column}
        errors={errors}
        editIndex={editIndex}
        editable={columnEditable}
        disabled={disabled}
        itemChange={this.itemChange}
        changeEditIndex={(idx) =>
          this.setState((s) => ({ editIndex: s.editIndex + idx }))
        }
        toggleDialog={() => this.toggleDialog()}
      />
    );
  };

  computeWidth = (width) => {
    const { useMinWidth, containerWidth } = this.state;
    const numberWidth = Number(width);
    const { columns } = this.props;
    const widthLeftover =
      (containerWidth -
        columns
          .map((c) => c.width)
          .reduce((acc, curr) => acc + (Number(curr) || 0), 0)) /
      columns.length;
    const result = useMinWidth
      ? numberWidth
      : numberWidth + widthLeftover - 1 / columns.length;
    return `${result}px`;
  };

  onRowClick = (row) => {
    const { canEdit, onRowClick } = this.props;
    const {
      dataItem: { gridIndex },
    } = row;
    if (canEdit) {
      this.setState({
        editIndex: gridIndex,
      });
    }
    if (onRowClick) {
      onRowClick(row);
    }
  };

  itemChange = (
    { dataItem: { gridIndex: editIndex, ...item }, field, value },
    applySideEffect = undefined
  ) => {
    const { onChange, dataGrid } = this.props;
    // if field is not set, we want to set the whole dataItem to value (handle multi-property updates)
    const propertiesToUpdate = field ? { [field]: value } : value;
    const dataItemToUpdate = { ...item, ...propertiesToUpdate };
    const dataUpdated = dataGrid.map(({ ...dataGridItem }, index) =>
      index === editIndex ? dataItemToUpdate : dataGridItem
    );
    onChange(
      typeof applySideEffect === "function"
        ? applySideEffect(editIndex, dataItemToUpdate, dataUpdated)
        : dataUpdated
    );
  };

  onItemDelete = () => {
    const { onChange, dataGrid } = this.props;
    const { editIndex } = this.state;
    onChange([...dataGrid.filter((item, index) => index !== editIndex)]);
    this.setState({
      editIndex: null,
    });
    this.toggleDialog();
    toastService.addToast({
      id: "delete-item-success",
      type: "success",
      message: "Donnée supprimée avec succès",
    });
  };

  toggleDialog = () => {
    this.setState((prevState) => ({
      dialogIsOpen: !prevState.dialogIsOpen,
    }));
  };

  handleOnScroll = () => {
    const maxScroll =
      this.scrollElement.current.scrollWidth -
      this.scrollElement.current.offsetWidth;
    if (this.scrollElement.current.scrollLeft > maxScroll) {
      this.scrollElement.current.scrollLeft = maxScroll;
    }
  };

  render() {
    const { editIndex, dialogIsOpen } = this.state;
    const {
      columns,
      dataGrid,
      onItemAdd,
      canAdd,
      canEdit,
      disabled,
      onRowDoubleClick,
      style,
      className,
      title,
      gridName,
      sortable,
      sort,
      onSortChange,
    } = this.props;
    return (
      <>
        <div className="k-display-block">
          {canAdd && (
            <div className="k-d-flex k-align-items-baseline">
              <Button
                title="Ajouter"
                type="button"
                className="k-button k-mb-4"
                onClick={onItemAdd}
                disabled={disabled}
              >
                Ajouter
              </Button>
              <h3 className="k-ml-4">{title}</h3>
            </div>
          )}
          <Grid
            style={
              gridName === "grilleDonneesAnnuelles"
                ? {
                    ...style,
                    maxWidth: "650px",
                  }
                : style
            }
            className={`${className} ${
              (!canEdit || (canEdit && !disabled)) && "grid-editable"
            }`}
            ref={this.gridRef}
            data={
              sortable && sort.length > 0
                ? orderBy(
                    dataGrid.map((item, gridIndex) => ({
                      ...item,
                      inEdit: gridIndex === editIndex,
                      gridIndex,
                    })),
                    sort[0]
                  )
                : dataGrid.map((item, gridIndex) => ({
                    ...item,
                    inEdit: gridIndex === editIndex,
                    gridIndex,
                  }))
            }
            rowHeight={46}
            onItemChange={this.itemChange}
            onRowClick={this.onRowClick}
            onRowDoubleClick={onRowDoubleClick}
            editField="inEdit"
            selectedField="selected"
            sortable={sortable}
            sort={sort}
            onSortChange={onSortChange}
            onScroll={this.handleOnScroll}
          >
            {columns.map((column, columnIndex) => {
              return (
                <GridColumn
                  key={column.field || `column-${columnIndex}`}
                  headerClassName={column.headerClassName}
                  title={column.title}
                  width={
                    gridName === "grilleDonneesAnnuelles"
                      ? "200px"
                      : (gridName === "gridAdmin" && column.type === "delete") ? "200px":
                      (gridName === "gridAdminTech" && column.type === "delete") ? "300px" : 
                      this.computeWidth(column.width)
                  }
                  field={column.field}
                  cell={this.GridCell}
                />
              );
            })}
          </Grid>
        </div>
        {dialogIsOpen && (
          <Dialog
            title={"Confirmation suppression"}
            onClose={this.toggleDialog}
          >
            <p>Confirmez-vous la suppression de la ligne sélectionnée ?</p>
            <DialogActionsBar>
              <Button onClick={this.toggleDialog}>Non</Button>
              <Button onClick={this.onItemDelete}>Oui</Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </>
    );
  }
}

CustomGrid.propTypes = {
  dataGrid: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fieldId: PropTypes.string,
  onChange: PropTypes.func,
  onItemAdd: PropTypes.func,
  errors: PropTypes.array,
  canAdd: PropTypes.bool,
  canEdit: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  gridName: PropTypes.string,
  sortable: PropTypes.bool,
  sort: PropTypes.array,
  onSortChange: PropTypes.func,
};

CustomGrid.defaultProps = {
  onItemAdd: () => {},
  errors: [],
  canAdd: false,
  canEdit: false,
  disabled: false,
  onRowDoubleClick: () => {},
  onRowClick: () => {},
  fieldId: null,
  onChange: () => {},
  style: {},
  className: "",
  title: "",
  gridName: "",
  sortable: false,
  sort: [],
  onSortChange: () => {},
};
registerForIntl(CustomGridCell);
export default CustomGrid;
