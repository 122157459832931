import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@progress/kendo-react-layout";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../utils/withRouter";
import { attemptLogin, attemptLoginAad } from "../../redux/actions/auth";
import { DOMAINE_OPTICHEVRE } from "../../redux/reducers/app";
import { loadAadConfig, msalContainer, setAzureAccount} from "../../Azure.js";

const mapStateToProps = (state) => ({
  domaine: state.app.domaine
});

const mapDispatchToProps = (dispatch) => ({
  reduxLogin: async (loginInfos) => {
    return dispatch(attemptLogin(loginInfos));
  },
  reduxLoginAad: async (loginInfos) => {
    return dispatch(attemptLoginAad(loginInfos));
  },
});


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInactivityDialog: false,
      isLoading: false
    };
  }

  async componentDidMount() {
    document.addEventListener("AadLoginOk", this.handleLoginAad);
    document.addEventListener("StartLoading", this.handleStartLoading);
    document.addEventListener("StopLoading", this.handleStopLoading);
    const { router } = this.props;
    this.setState({ isLoading: true });
    await loadAadConfig(router);
    this.setState({ isLoading: false });
    setAzureAccount();
    const name = new URLSearchParams(router.location).get("disconnected");
    if (name === "inactivity") {
      this.setState({ showInactivityDialog: true });
    }
  }

  handleLogin = async (loginInfos) => {
    const { reduxLogin, router } = this.props;
    try {
      await reduxLogin(loginInfos);
      router.navigate("/app",{replace : true});
    } catch (error) {
      toastService.addToast({
        id: "login-eror",
        type: "error",
        message: error.message,
      });
    }
  }

  handleStartLoading = async () => {
    console.log('handleStartLoading')
    this.setState({ isLoading: true });
  }

  handleStopLoading = async () => {
    console.log('handleStopLoading')
    this.setState({ isLoading: false });
  }

  handleLoginAad = async (loginInfos) => {
    const { reduxLoginAad, router } = this.props;
    try {
      await reduxLoginAad(loginInfos);
      router.navigate("/app",{replace : true});
    } catch (error) {
      toastService.addToast({
        id: "login-eror",
        type: "error",
        message: error.message,
      });
    }
  }

  handleLoginRedirect = () => {
    this.handleStartLoading()
    if (msalContainer.msalInstance !== undefined && msalContainer.msalInstance !== null) {
      msalContainer.msalInstance.loginRedirect();
    }
  };

  closeInactivityDialog = () => {
    const { router } = this.props;
    this.setState({ showInactivityDialog: false });
    router.navigate({ search: new URLSearchParams().toString() });
  };

  render() {
    const { showInactivityDialog, isLoading } = this.state;
    const { domaine } = this.props;

    return (
      <div className="center-page login">
        {showInactivityDialog && (
          <Dialog title="Déconnecté" onClose={this.closeInactivityDialog}>
            Vous avez été déconnecté suite à votre inactivité. Veuillez vous
            reconnecter.
          </Dialog>
        )}
        <Card>
          <CardHeader style={{textAlign: "center"}}>
            <img
              src={
                // todo handle domaine according to url
                domaine === DOMAINE_OPTICHEVRE
                  ? `${process.env.PUBLIC_URL}/images/logo_opti_chevre_login.png`
                  : `${process.env.PUBLIC_URL}/images/logo_chevry_plan_login.png`
              }
              alt="Logo"
              style={{ maxHeight: "4.5rem" }}
            />
          </CardHeader>
          <CardBody>
            <div className="k-form-buttons">
              {isLoading &&
                <div className="k-loading-mask">
                  <span className="k-loading-text">Loading</span>
                  <div className="k-loading-image"></div>
                  <div className="k-loading-color"></div>
                </div>
              }
              <Button
                name="btnSubmit"
                id="btnSubmit"
                type={"submit"}
                className="k-button"
                icon="arrow-60-right"
                onClick={this.handleLoginRedirect}
                disabled={isLoading}
                >
                Se connecter
              </Button>
            </div>
          </CardBody>
          <CardFooter className="k-d-flex k-justify-content-between k-align-items-end">
            <div style={{textAlign: "center", width: "100%", height: "75px"}}>
            </div>
            {/* c'est pour pousser l'image du coin à droite ! */}
            {domaine === DOMAINE_OPTICHEVRE && <span />}
            <img
              src={`${process.env.PUBLIC_URL}/images/${
                domaine === DOMAINE_OPTICHEVRE
                  ? "corner_btpl.png"
                  : "corner_adm_gris.png"
              }`}
              alt="Logo"
              style={{ maxHeight: "1.7rem" }}
            />
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
