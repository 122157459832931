import { Button, Chip } from "@progress/kendo-react-buttons";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import { compose } from "redux";
import dataSource from "../../../dataSource/dataSource";
import toastService from "../../../utils/toastService";
import AlimBoucs from "./AlimBoucs";
import AlimChevres from "./AlimChevres";
import AlimChevrettes from "./AlimChevrettes";
import DonneesTechniques from "./DonneesTechniques";
import PaieDeLait from "./PaieDeLait";
import validateForm from "./ValidateFormValues";
import PopupAjoutAliment from "./PopupAjoutAliment";
import ReportViewer from "../pdf/ReportViewer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

// define constants for nutrients
const FOURRAGE = "Fourrages";
const ALIMENTS_SIMPLES = "AlimentsSimples";

// define constants for animals
const CHEVRES = "alimChevres";
const PETITES_CHEVRETTES = "alimPetitesChevrettes";
const GRANDES_CHEVRETTES = "alimGrandesChevrettes";
const BOUCS = "alimBoucs";

export const CHEVRES_FOURRAGE = `${CHEVRES}${FOURRAGE}`;
export const CHEVRES_ALIMENTS_SIMPLES = `${CHEVRES}${ALIMENTS_SIMPLES}`;
export const PETITES_CHEVRETTES_FOURRAGE = `${PETITES_CHEVRETTES}${FOURRAGE}`;
export const PETITES_CHEVRETTES_ALIMENTS_SIMPLES = `${PETITES_CHEVRETTES}${ALIMENTS_SIMPLES}`;
export const GRANDES_CHEVRETTES_FOURRAGE = `${GRANDES_CHEVRETTES}${FOURRAGE}`;
export const GRANDES_CHEVRETTES_ALIMENTS_SIMPLES = `${GRANDES_CHEVRETTES}${ALIMENTS_SIMPLES}`;
export const BOUCS_FOURRAGE = `${BOUCS}${FOURRAGE}`;
export const BOUCS_ALIMENTS_SIMPLES = `${BOUCS}${ALIMENTS_SIMPLES}`;

// defining constants for tabs
const TAB_DONNEES_TECHNIQUES = "donneesTechniques";
const TAB_PAIE_DE_LAIT = "paieDeLait";
const TAB_ALIM_CHEVRES = "alimChevres";
const TAB_ALIM_CHEVRETTES = "alimChevrettes";
const TAB_ALIM_BOUCS = "alimBoucs";

const mapStateToProps = (state) => ({
  domaineId: state.auth.domaineId,
  typeUtilisateurId: state.auth.typeUtilisateurId,
});

class FicheDonneeMensuelle extends Component {
  tabItems = [
    { key: TAB_DONNEES_TECHNIQUES, title: "Données techniques" },
    { key: TAB_PAIE_DE_LAIT, title: "Paie de lait" },
    {
      key: TAB_ALIM_CHEVRES,
      title: "Alim Chèvres",
      ok: "alimentationChevresOk",
    },
    {
      key: TAB_ALIM_CHEVRETTES,
      title: "Alim chevrettes",
      ok: "alimentationChevrettesOk",
    },
    {
      key: TAB_ALIM_BOUCS,
      title: "Alim boucs",
      ok: "alimentationBoucsOk",
    },
  ];

  allMonths = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      donneeMensuelle: null,
      selected: 0,
      loading: true,
      alimentsFourrages: [],
      alimentsAlimentsSimples: [],
      canEdit: false,
      alimentsHierarchy: [],
      showAlimentDialog: false,
      isSubmit: false,
    };
  }

  async componentDidMount() {
    const { idFiche } = this.props.router.params;
    // load DM data
    const {
      alimentation,
      ...otherDonneeMensuelleProps
    } = await dataSource.loadDonneeMensuelleById(idFiche);

    // load alimentations
    const [typesAliments, categoriesAliments, alimentsGroupes] = [
      await dataSource.loadTypesAliments(),
      await dataSource.loadCategoriesAliments(),
      await dataSource.loadGroupeAliments(otherDonneeMensuelleProps.groupeId),
    ];

    // construct aliments structure
    const alimentsHierarchy = typesAliments.map((typeAliment) => ({
      ...typeAliment,
      categories: categoriesAliments
        .filter(
          (categorieAliment) =>
            categorieAliment.parent_id === typeAliment.rang_modaliste
        )
        .map((categorie) => ({
          ...categorie,
          aliments: alimentsGroupes.filter(
            (aliment) => aliment.categorieAliment === categorie.rang_modaliste
          ),
        })),
    }));
    const computeListAliment = (typesAlimentsHierarchy) =>
      typesAlimentsHierarchy.flatMap((typeAliment) =>
        typeAliment.categories.flatMap((c) =>
          c.aliments.map(
            ({ nomAliment, groupeAlimentId, msPourcent, prixEuroHT }) => ({
              groupText: c.abrev_modaliste,
              text: nomAliment,
              groupeAlimentId,
              msPourcent,
              prixEuroHT,
            })
          )
        )
      );

    /** *
     * define list of aliments usable in {GridComboBox} component
     *  */
    const alimentsFourrages = computeListAliment(
      alimentsHierarchy.filter((a) => a.rang_modaliste === 1)
    );
    const alimentsAlimentsSimples = computeListAliment(
      alimentsHierarchy.filter((a) => [2, 3, 4].includes(a.rang_modaliste))
    );

    // compute local DM alimentation arrays
    const alimentationParsed = alimentation.map(
      ({ isAVolonte, ...ligneAlimentation }) => ({
        ...ligneAlimentation,
        isAVolonte: isAVolonte === 1,
      })
    );
    const alimChevres = alimentationParsed.filter(
      (item) => item.typeAnimal === 1
    );
    const alimChevresFourrages = alimChevres.filter(
      (item) => item.typeAliment === 1
    );
    const alimChevresAlimentsSimples = alimChevres.filter(
      (item) =>
        item.typeAliment === 2 ||
        item.typeAliment === 3 ||
        item.typeAliment === 4
    );
    const alimPetitesChevrettes = alimentationParsed.filter(
      (item) => item.typeAnimal === 2
    );
    const alimPetitesChevrettesFourrages = alimPetitesChevrettes.filter(
      (item) => item.typeAliment === 1
    );
    const alimPetitesChevrettesAlimentsSimples = alimPetitesChevrettes.filter(
      (item) =>
        item.typeAliment === 2 ||
        item.typeAliment === 3 ||
        item.typeAliment === 4
    );
    const alimGrandesChevrettes = alimentationParsed.filter(
      (item) => item.typeAnimal === 3
    );
    const alimGrandesChevrettesFourrages = alimGrandesChevrettes.filter(
      (item) => item.typeAliment === 1
    );
    const alimGrandesChevrettesAlimentsSimples = alimGrandesChevrettes.filter(
      (item) =>
        item.typeAliment === 2 ||
        item.typeAliment === 3 ||
        item.typeAliment === 4
    );
    const alimBoucs = alimentationParsed.filter(
      (item) => item.typeAnimal === 4
    );
    const alimBoucsFourrages = alimBoucs.filter(
      (item) => item.typeAliment === 1
    );
    const alimBoucsAlimentsSimples = alimBoucs.filter(
      (item) =>
        item.typeAliment === 2 ||
        item.typeAliment === 3 ||
        item.typeAliment === 4
    );
    this.setState({
      loading: false,
      donneeMensuelle: {
        ...otherDonneeMensuelleProps,
        alimChevresFourrages,
        alimChevresAlimentsSimples,
        alimPetitesChevrettesFourrages,
        alimPetitesChevrettesAlimentsSimples,
        alimGrandesChevrettesFourrages,
        alimGrandesChevrettesAlimentsSimples,
        alimBoucsFourrages,
        alimBoucsAlimentsSimples,
      },
      alimentsHierarchy,
      alimentsFourrages,
      alimentsAlimentsSimples,
    });
  }

  changeValue = (key, value) => this.formRef.current.onChange(key, { value });

  handleSelect = (e) => {
    if (
      [
        "alimentationChevresOk",
        "alimentationChevrettesOk",
        "alimentationBoucsOk",
      ].includes(this.tabItems[e.selected].ok)
    ) {
      this.formRef.current.onChange(this.tabItems[e.selected].ok, {
        value: 1,
      });
    }
    this.setState({ selected: e.selected });
  };

  filterEmptyLine = (array) => {
    return array.filter(
      (aliment) =>
        ![
          aliment.msPourcent,
          aliment.prixAchatEuroHT,
          aliment.quantiteLot1,
          aliment.quantiteLot2,
          aliment.quantiteLot3,
          aliment.quantiteLot4,
          aliment.quantiteLotTari,
        ].every((element) => element == null)
    );
  };

  submitValues = async ({
    values: {
      alimChevresFourrages,
      alimChevresAlimentsSimples,
      alimPetitesChevrettesFourrages,
      alimPetitesChevrettesAlimentsSimples,
      alimGrandesChevrettesFourrages,
      alimGrandesChevrettesAlimentsSimples,
      alimBoucsFourrages,
      alimBoucsAlimentsSimples,
      ...otherValues
    },
  }) => {
    try {
      // transform the form values object into a API-Compatible object
      const alimChevresFourragesFiltered = this.filterEmptyLine(
        alimChevresFourrages
      );
      const alimChevresAlimentsSimplesFiltered = this.filterEmptyLine(
        alimChevresAlimentsSimples
      );
      const alimPetitesChevrettesFourragesFiltered = this.filterEmptyLine(
        alimPetitesChevrettesFourrages
      );
      const alimPetitesChevrettesAlimentsSimplesFiltered = this.filterEmptyLine(
        alimPetitesChevrettesAlimentsSimples
      );
      const alimGrandesChevrettesFourragesFiltered = this.filterEmptyLine(
        alimGrandesChevrettesFourrages
      );
      const alimGrandesChevrettesAlimentsSimplesFiltered = this.filterEmptyLine(
        alimGrandesChevrettesAlimentsSimples
      );
      const alimBoucsFourragesFiltered = this.filterEmptyLine(
        alimBoucsFourrages
      );
      const alimBoucsAlimentsSimplesFiltered = this.filterEmptyLine(
        alimBoucsAlimentsSimples
      );

      const newDonneeMensuelle = {
        ...otherValues,
        alimentation: [
          ...alimChevresFourragesFiltered,
          ...alimChevresAlimentsSimplesFiltered,
          ...alimPetitesChevrettesFourragesFiltered,
          ...alimPetitesChevrettesAlimentsSimplesFiltered,
          ...alimGrandesChevrettesFourragesFiltered,
          ...alimGrandesChevrettesAlimentsSimplesFiltered,
          ...alimBoucsFourragesFiltered,
          ...alimBoucsAlimentsSimplesFiltered,
        ].map((ligneAlimentation) => ({
          ...ligneAlimentation,
          isAVolonte: ligneAlimentation.isAVolonte === true ? 1 : 0,
        })),
      };
      await dataSource.updateDonneeMensuelleById(
        newDonneeMensuelle.donneesMensuellesID,
        newDonneeMensuelle
      );

      // show toast for success
      toastService.addToast({
        id: "dm-success",
        type: "success",
        message: "Vos informations ont bien été mises à jour",
      });
    } catch (err) {
      // show toast for error
      toastService.addToast({
        id: "dm-error",
        type: "error",
        message: err.message,
      });
    }
  };

  validateDM = async () => {
    this.formRef.current.onChange("statuT_VALID_ID", {
      value: 3,
    });
    await this.formRef.current.onSubmit();
    this.setState({ canEdit: false });
  };

  openAlimentDialog = (resolve, reject) => {
    const { showAlimentDialog } = this.state;
    this.setState({
      showAlimentDialog: !showAlimentDialog,
      resolveNewAliment: resolve,
      rejectNewAliment: reject,
    });
  };

  goToDonneeMensuelle = (donneesMensuellesID) => {
    const { router } = this.props;
    const { list, loading, statuts, groupes, elevages, elevage, arrayMonthByIndex} = this.props.router.location.state;

    router.navigate({
      pathname: `/app/donnees-mensuelles/saisie/fiche/${elevage.nom}/${donneesMensuellesID}`
    },{
      replace : true,
        state: {
        list,
        loading,
        statuts,
        groupes,
        elevages,
        elevage,
        arrayMonthByIndex
      }
    });
    window.location.reload();
  };

  closeAlimentDialog = () => {
    const { rejectNewAliment } = this.state;
    rejectNewAliment("close");
    this.setState({ showAlimentDialog: false });
  };

  toggleEditMode = () => {
    const { canEdit } = this.state;
    this.setState((prevState) => ({
      canEdit: !prevState.canEdit,
    }));
    if (canEdit) {
      this.formRef.current.resetForm();
    }
  };

  togglePDFPreview = () => {
    const { showPrintDialog } = this.state;
    this.setState({ showPrintDialog: !showPrintDialog });
  };

  isObject = (object) => {
    return object != null && typeof object === "object";
  };

  isEqualObject = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (areObjects && !this.isEqualObject(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  };

  handleResoleNewAliment = async () => {
    const { idFiche } = this.props.router.params;
    const {
      ...otherDonneeMensuelleProps
    } = await dataSource.loadDonneeMensuelleById(idFiche);

    const [typesAliments, categoriesAliments, alimentsGroupes] = [
      await dataSource.loadTypesAliments(),
      await dataSource.loadCategoriesAliments(),
      await dataSource.loadGroupeAliments(otherDonneeMensuelleProps.groupeId),
    ];

    // construct aliments structure
    const alimentsHierarchy2LeRetour = typesAliments.map((typeAliment) => ({
      ...typeAliment,
      categories: categoriesAliments
        .filter(
          (categorieAliment) =>
            categorieAliment.parent_id === typeAliment.rang_modaliste
        )
        .map((categorie) => ({
          ...categorie,
          aliments: alimentsGroupes.filter(
            (aliment) => aliment.categorieAliment === categorie.rang_modaliste
          ),
        })),
    }));

    const computeListAliment = (typesAlimentsHierarchy) =>
      typesAlimentsHierarchy.flatMap((typeAliment) =>
        typeAliment.categories.flatMap((c) =>
          c.aliments.map(
            ({ nomAliment, groupeAlimentId, msPourcent, prixEuroHT }) => ({
              groupText: c.abrev_modaliste,
              text: nomAliment,
              groupeAlimentId,
              msPourcent,
              prixEuroHT,
            })
          )
        )
      );

    const alimentsAlimentsSimples2LeRetour = computeListAliment(
      alimentsHierarchy2LeRetour.filter((a) =>
        [2, 3, 4].includes(a.rang_modaliste)
      )
    );

    this.setState({
      alimentsAlimentsSimples: alimentsAlimentsSimples2LeRetour,
    });
  };

  render() {
    const {
      selected,
      donneeMensuelle,
      loading,
      alimentsAlimentsSimples,
      alimentsFourrages,
      canEdit,
      alimentsHierarchy,
      showAlimentDialog,
      resolveNewAliment,
      showPrintDialog,
      isSubmit,
    } = this.state;

    const { router, domaineId } = this.props;

    const arrayMonthByIndex = this.props.router.location.state.arrayMonthByIndex;
    let indexOfDonneesMensuelleID = arrayMonthByIndex.indexOf(Number(this.props.router.params.idFiche));
    const previousMonthToDisplay = arrayMonthByIndex[indexOfDonneesMensuelleID + 1] || null;
    const nextMonthToDisplay = arrayMonthByIndex[indexOfDonneesMensuelleID - 1] || null;

    const currentMonth = this.allMonths[[donneeMensuelle?.mois] - 1];
    const lastMonth = this.allMonths[[donneeMensuelle?.mois] - 2];
    const currentYear = donneeMensuelle?.annee;

    return (
      <div className="k-d-flex k-flex-column k-m-4">
        {loading === true && (
          <div className="flex-fill k-text-center k-mt-18">
            <Loader themeColor="primary" />
          </div>
        )}
        {loading === false && (
          <Form
            ref={this.formRef}
            validator={validateForm}
            initialValues={donneeMensuelle}
            onSubmitClick={this.submitValues}
            render={(formProps) => {
              const {
                tabsBrowsed: errorsTabsBrowsed,
                donneesTechniques: errorsDonneesTechniques,
                paieDeLait: errorsPaieDeLait,
                alimChevresEffectifs: errorsAlimChevresEffectifs,
                alimChevresFourrages: errorsChevresFourrages,
                alimChevresAlimentsSimples: errorsChevresAlimentsSimples,
                alimPetitesChevrettesFourrages: errorsPetitesChevrettesFourrages,
                alimPetitesChevrettesAlimentsSimples: errorsPetitesChevrettesAlimentsSimples,
                alimGrandesChevrettesFourrages: errorsGrandesChevrettesFourrages,
                alimGrandesChevrettesAlimentsSimples: errorsGrandesChevrettesAlimentsSimples,
                alimBoucsFourrages: errorsBoucsFourrages,
                alimBoucsAlimentsSimples: errorsBoucsAlimentsSimples,
                ...otherErrors
              } = formProps.errors;
              const DMErrors = Object.values(formProps.errors).filter(
                (item) => item !== ""
              );
              return (
                <>
                  <FormElement>
                    <div className="k-display-flex k-justify-content-between k-align-items-center k-mb-4">
                      <div className="k-d-inline-flex k-align-items-baseline">
                        <h2 className="text-color-primary k-my-0">
                          {this.props.router.params.nomElevage}
                        </h2>
                        <h3 className="k-ml-4 k-my-0">
                          Données mensuelles {currentMonth} / {currentYear}
                        </h3>
                      </div>

                      <Chip
                        className="chip-statut"
                        text={
                          [
                            {
                              text: "Généré",
                              key: 1,
                            },
                            {
                              text: "En cours",
                              key: 2,
                            },
                            {
                              text: "Validé",
                              key: 3,
                            },
                          ].find(
                            (e) =>
                              e.key === formProps.valueGetter("statuT_VALID_ID")
                          ).text
                        }
                        value="chip"
                        look="outlined"
                        disabled
                      />
                    </div>

                    {!DMErrors.every(
                      (item) => Object.keys(item).length === 0
                    ) && (
                      <div className="row">
                        <div className="panel-error k-my-4">
                          <PanelBar>
                            <PanelBarItem
                              title="Erreurs dans le formulaire"
                              className="k-state-expanded"
                              style={{ width: "100%" }}
                            >
                              {[
                                errorsTabsBrowsed,
                                errorsDonneesTechniques,
                                errorsPaieDeLait,
                                errorsAlimChevresEffectifs,
                                errorsChevresFourrages,
                                errorsChevresAlimentsSimples,
                                errorsPetitesChevrettesFourrages,
                                errorsPetitesChevrettesAlimentsSimples,
                                errorsGrandesChevrettesFourrages,
                                errorsGrandesChevrettesAlimentsSimples,
                                errorsBoucsFourrages,
                                errorsBoucsAlimentsSimples,
                                {
                                  tabTitle: "Données techniques",
                                  errors: otherErrors,
                                },
                              ].map(({ tabTitle, ...errors }) => {
                                return !!tabTitle &&
                                  Object.values(errors).filter(
                                    (error) => !!error.message
                                  ).length > 0 ? (
                                  <div key={tabTitle}>
                                    <h3 style={{ marginBottom: "0.5em" }}>
                                      {tabTitle}
                                    </h3>
                                    {Object.entries(errors).map(
                                      ([key, { message, lines, tabIndex }]) =>
                                        key === "tabsBrowsedInvalid" ? (
                                          [
                                            <span key={tabIndex} className="span-join">
                                              {message.length > 1
                                                ? "Les onglets "
                                                : "L' onglet "}
                                            </span>,
                                          ]
                                            .concat(
                                              message
                                                .map((onglet) => {
                                                  return (
                                                    <Button
                                                      type="button"
                                                      key={key}
                                                      className="k-mb-2 button-error button-join"
                                                      onClick={() => {
                                                        this.formRef.current.onChange(
                                                          this.tabItems[
                                                            onglet.tabIndex
                                                          ].ok,
                                                          {
                                                            value: 1,
                                                          }
                                                        );
                                                        this.setState({
                                                          selected:
                                                            onglet.tabIndex,
                                                        });
                                                      }}
                                                    >
                                                      {onglet.libelle}
                                                    </Button>
                                                  );
                                                })
                                                .reduce((prev, curr) => [
                                                  prev,
                                                  ", ",
                                                  curr,
                                                ])
                                            )
                                            .concat([
                                              <span key={tabIndex} >
                                                {message.length > 1
                                                  ? " ne sont pas parcouru(s)"
                                                  : " n'est pas parcouru"}
                                              </span>,
                                            ])
                                        ) : (
                                          <div key={key}>
                                            <Button
                                              type="button"
                                              key={key}
                                              className="k-mb-2 button-error"
                                              onClick={() =>
                                                this.setState({
                                                  selected:
                                                    tabIndex != null
                                                      ? tabIndex
                                                      : 0,
                                                })
                                              }
                                            >
                                              {message}{" "}
                                              {lines && lines.length > 0 && (
                                                <span>
                                                  (Ligne
                                                  {lines.length > 1 && "s"}{" "}
                                                  {lines
                                                    .map((l) => l + 1)
                                                    .join(",")}
                                                  )
                                                </span>
                                              )}
                                            </Button>
                                          </div>
                                        )
                                    )}
                                  </div>
                                ) : null;
                              })}
                            </PanelBarItem>
                          </PanelBar>
                        </div>
                      </div>
                    )}
                    <TabStrip
                      selected={selected}
                      onSelect={this.handleSelect}
                      className="tab-strip-responsive"
                    >
                      {this.tabItems.map((tabItem) => {
                        let template;
                        switch (tabItem.key) {
                          case TAB_DONNEES_TECHNIQUES:
                            template = (
                              <DonneesTechniques
                                currentMonth={currentMonth}
                                lastMonth={lastMonth}
                                currentYear={currentYear}
                                errors={formProps.errors.donneesTechniques}
                                valueGetter={formProps.valueGetter}
                                onChange={this.changeValue}
                                canEdit={canEdit}
                              />
                            );
                            break;
                          case TAB_PAIE_DE_LAIT:
                            template = (
                              <PaieDeLait
                                dataPaieDeLait={formProps.valueGetter(
                                  "paieDeLait"
                                )}
                                onChange={(newData) =>
                                  formProps.onChange("paieDeLait", {
                                    value: [...newData],
                                  })
                                }
                                errors={formProps.errors.paieDeLait}
                                canEdit={canEdit}
                              />
                            );
                            break;
                          case TAB_ALIM_CHEVRES:
                            template = (
                              <AlimChevres
                                alimentsSimples={alimentsAlimentsSimples}
                                alimentsFourrages={alimentsFourrages}
                                valueGetter={formProps.valueGetter}
                                onChange={this.changeValue}
                                errors={{
                                  errorsChevresFourrages,
                                  errorsChevresAlimentsSimples,
                                  errorsAlimChevresEffectifs,
                                }}
                                canEdit={canEdit}
                                onAddAliment={this.openAlimentDialog}
                              />
                            );
                            break;
                          case TAB_ALIM_CHEVRETTES:
                            template = (
                              <AlimChevrettes
                                alimentsSimples={alimentsAlimentsSimples}
                                alimentsFourrages={alimentsFourrages}
                                valueGetter={formProps.valueGetter}
                                onChange={this.changeValue}
                                errors={{
                                  errorsPetitesChevrettesFourrages,
                                  errorsPetitesChevrettesAlimentsSimples,
                                  errorsGrandesChevrettesFourrages,
                                  errorsGrandesChevrettesAlimentsSimples,
                                }}
                                canEdit={canEdit}
                                onAddAliment={this.openAlimentDialog}
                              />
                            );
                            break;
                          case TAB_ALIM_BOUCS:
                            template = (
                              <AlimBoucs
                                alimentsSimples={alimentsAlimentsSimples}
                                alimentsFourrages={alimentsFourrages}
                                valueGetter={formProps.valueGetter}
                                onChange={this.changeValue}
                                errors={{
                                  errorsBoucsFourrages,
                                  errorsBoucsAlimentsSimples,
                                }}
                                canEdit={canEdit}
                                onAddAliment={this.openAlimentDialog}
                              />
                            );
                            break;
                          default:
                            template = null;
                            break;
                        }
                        return (
                          <TabStripTab key={tabItem.key} title={tabItem.title}>
                            {template}
                          </TabStripTab>
                        );
                      })}
                    </TabStrip>
                  </FormElement>
                  <div className="k-display-flex k-justify-content-center sticky-footer">
                    {!canEdit && (
                      <>
                      {previousMonthToDisplay !== null && (
                        <>
                          <Button
                          className="k-mr-4"
                          onClick={ () => {
                            this.goToDonneeMensuelle(previousMonthToDisplay)
                          }}
                          >
                          Mois précédent
                          </Button>
                        </>
                      )}
                        <Button
                          className="k-mr-4"
                          icon="close"
                          title="Fermer"
                          onClick={() => {
                            router.navigate({pathname: `/app/donnees-mensuelles/saisie/`},{replace : true})
                          }}
                        >
                          Fermer
                        </Button>
                        <Button
                          className="k-mr-4"
                          icon={"pencil"}
                          title={"Editer"}
                          onClick={() => {
                            this.toggleEditMode();
                            this.setState({ isSubmit: false });
                          }}
                          primary={isSubmit ? 'primary' : null}
                        >
                          {"Editer"}
                        </Button>
                        <Button
                          className="k-mr-4"
                          icon="print"
                          title="Voir les résulats"
                          onClick={this.togglePDFPreview}
                        >
                          Voir les résulats
                        </Button>
                        {nextMonthToDisplay !== null && (
                          <>
                            <Button
                            className="k-mr-4"
                            onClick={ () => {
                              this.goToDonneeMensuelle(nextMonthToDisplay)
                            }}
                            >
                            Mois suivant
                            </Button>
                          </>
                        )}
                      </>
                    )}

                    {canEdit && (
                      <>
                        <Button
                          icon="save"
                          className="k-ml-4"
                          primary={
                            !this.isEqualObject(
                              donneeMensuelle,
                              this.formRef.current.values
                            ) ? 'primary' : null
                          }
                          onClick={() => {
                            if (
                              formProps.valueGetter("statuT_VALID_ID") !== 3
                            ) {
                              formProps.onChange("statuT_VALID_ID", {
                                value: 2,
                              });
                            }
                            formProps.onSubmit();
                            this.setState({
                              isSubmit: true,
                            });
                          }}
                        >
                          Enregistrer les données
                        </Button>
                        <Button
                          className="k-ml-4"
                          icon="reload"
                          onClick={() => window.location.reload()}
                        >
                          Annuler la saisie
                        </Button>
                        <Button
                          className="k-ml-4"
                          icon={"undo"}
                          title={"Visualiser"}
                          onClick={() => window.location.reload()}
                          primary={isSubmit ? 'primary' : null}
                        >
                          {"Visualiser"}
                        </Button>

                        {
                          /* Tous les onglets doivent être parcourus */
                          [
                            "alimentationChevrettesOk",
                            "alimentationChevresOk",
                            "alimentationBoucsOk",
                          ]
                            .map((c) => formProps.valueGetter(c))
                            .every((v) => v !== 0) &&
                            /* Le statut ne doit pas être validé */
                            formProps.valueGetter("statuT_VALID_ID") !== 3 &&
                            /* Le tableau d'erreurs doit être vide */
                            DMErrors.every(
                              (item) => Object.keys(item).length === 0
                            ) && (
                              <Button
                                icon="check"
                                className="k-ml-4"
                                onClick={this.validateDM}
                                themeColor="primary"
                              >
                                Appliquer le statut &quot;Validé&quot;
                              </Button>
                            )
                        }
                      </>
                    )}
                  </div>
                </>
              );
            }}
          />
        )}
        {showAlimentDialog && (
          <>
            <PopupAjoutAliment
              history={router}
              donneeMensuelle={donneeMensuelle}
              alimentsAlimentsSimples={alimentsAlimentsSimples}
              resolveNewAliment={async (newAliment) => {
                await this.handleResoleNewAliment();
                resolveNewAliment(newAliment);
              }}
              alimentsHierarchy={alimentsHierarchy}
              closeAlimentDialog={this.closeAlimentDialog}
            />
          </>
        )}
        <QueryClientProvider client={queryClient}>
          {showPrintDialog && (
            <ReportViewer
              title={`Rapport Données Mensuelles Résultats`}
              params={{
                domaine: domaineId,
                codeEleveur: donneeMensuelle.societeAgricoleId,
                annee: donneeMensuelle.annee,
                mois: donneeMensuelle.mois,
              }}
              onClose={this.togglePDFPreview}
            ></ReportViewer>
          )}
        </QueryClientProvider>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter
)(FicheDonneeMensuelle);