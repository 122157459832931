import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import PropTypes from "prop-types";
import { Component } from "react";
import CustomGrid from "../../shared/CustomGrid";
import {
  GRANDES_CHEVRETTES_ALIMENTS_SIMPLES,
  GRANDES_CHEVRETTES_FOURRAGE,
  PETITES_CHEVRETTES_ALIMENTS_SIMPLES,
  PETITES_CHEVRETTES_FOURRAGE,
} from "./FicheDonneeMensuelle";

class AlimChevrettes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEditedGrid: undefined,
    };
  }

  render() {
    const {
      valueGetter,
      onChange,
      errors: {
        errorsPetitesChevrettesFourrages,
        errorsPetitesChevrettesAlimentsSimples,
        errorsGrandesChevrettesFourrages,
        errorsGrandesChevrettesAlimentsSimples,
      },
      alimentsFourrages,
      alimentsSimples,
      onAddAliment,
      canEdit,
    } = this.props;

    const columnsFourrage = (key) => {
      const { currentEditedGrid } = this.state;

      return [
        {
          field: "isAVolonte",
          title: "A volonté",
          type: "checkbox",
          width: 110,
          editable: () => currentEditedGrid === key,
        },
        {
          field: "nomAliment",
          title: "Nom du fourrage",
          type: "dropdowntree",
          dropdowntree: {
            list: alimentsFourrages,
            textField: "text",
            dataItemKey: "groupeAlimentId",
            groupKey: "groupText",
            value: ({ groupeAlimentId, nomAliment, nomCategorieAliment }) =>
              alimentsFourrages.find(
                (a) => a.groupeAlimentId === groupeAlimentId
              ) || {
                groupText: nomCategorieAliment,
                groupeAlimentId,
                text: nomAliment,
              },
            updateItem: (
              dataItem,
              { text, groupeAlimentId, msPourcent, prixEuroHT }
            ) => ({
              ...dataItem,
              groupeAlimentId,
              nomAliment: text,
              msPourcent,
              prixAchatEuroHT: prixEuroHT,
            }),
          },
          width: 220,
          editable: () => currentEditedGrid === key,
        },
        {
          field: "msPourcent",
          fieldProps: { spinners: false },
          title: "MS %",
          type: "number",
          width: 80,
          format: "n1",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "prixAchatEuroHT",
          fieldProps: { spinners: false },
          title: "Prix € HT",
          type: "number",
          width: 110,
          format: "n0",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "quantiteLot1",
          fieldProps: { spinners: false },
          title: "Qté (kg/ani./j)",
          type: "number",
          width: 140,
          format: "n2",
          editable: () => currentEditedGrid === key,
        },
        {
          type: "delete",
          width: 60,
          editable: () => currentEditedGrid === key,
        },
      ];
    };

    const columnsAlimentsSimples = (key) => {
      const { currentEditedGrid } = this.state;

      return [
        {
          width: 110,
          editable: () => false,
        },
        {
          field: "nomAliment",
          title: "Nom de l'aliment",
          type: "dropdowntree",
          dropdowntree: {
            list: alimentsSimples,
            textField: "text",
            dataItemKey: "groupeAlimentId",
            groupKey: "groupText",
            canAdd: true,
            onClickAdd: onAddAliment,
            value: ({ groupeAlimentId, nomAliment, nomCategorieAliment }) =>
              alimentsSimples.find(
                (a) => a.groupeAlimentId === groupeAlimentId
              ) || {
                groupText: nomCategorieAliment,
                groupeAlimentId,
                text: nomAliment,
              },
            updateItem: (
              dataItem,
              { text, groupeAlimentId, msPourcent, prixEuroHT }
            ) => ({
              ...dataItem,
              groupeAlimentId,
              nomAliment: text,
              msPourcent,
              prixAchatEuroHT: prixEuroHT,
            }),
          },
          width: 220,
          editable: () => currentEditedGrid === key,
        },
        {
          field: "msPourcent",
          fieldProps: { spinners: false },
          title: "MS %",
          type: "number",
          width: 80,
          format: "n1",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "prixAchatEuroHT",
          fieldProps: { spinners: false },
          title: "Prix € HT",
          type: "number",
          width: 110,
          format: "n0",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "quantiteLot1",
          fieldProps: { spinners: false },
          title: "Qté (g/ani./j)",
          type: "number",
          width: 140,
          format: "n0",
          editable: () => currentEditedGrid === key,
        },
        {
          type: "delete",
          width: 60,
          editable: () => currentEditedGrid === key,
        },
      ];
    };

    const petitesChevrettesfourrages = valueGetter(PETITES_CHEVRETTES_FOURRAGE);
    const petitesChevrettesAlimentsSimples = valueGetter(
      PETITES_CHEVRETTES_ALIMENTS_SIMPLES
    );
    const grandesChevrettesFourrages = valueGetter(GRANDES_CHEVRETTES_FOURRAGE);
    const grandesChevrettesAlimentsSimples = valueGetter(
      GRANDES_CHEVRETTES_ALIMENTS_SIMPLES
    );
    const errorsFouragesPC = Object.entries(errorsPetitesChevrettesFourrages)
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    const errorsFouragesGC = Object.entries(errorsGrandesChevrettesFourrages)
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    const errorsAlimentsSimplesPC = Object.entries(
      errorsPetitesChevrettesAlimentsSimples
    )
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    const errorsAlimentsSimplesGC = Object.entries(
      errorsGrandesChevrettesAlimentsSimples
    )
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    return (
      <div className="k-d-flex k-flex-column k-my-2">
        <div className="k-d-flex">
          <PanelBar
            className={`${canEdit && "panelbar-editable"}`}
            style={{ width: "50%" }}
          >
            <PanelBarItem title="Petites chevrettes" expanded>
              <div className="k-m-4">
                <CustomGrid
                  title="Fourrages (Qté en Kg)"
                  className="grid-fourrage-chevrette k-mb-4"
                  canAdd
                  dataGrid={petitesChevrettesfourrages}
                  fieldId="dmAlimentationId"
                  columns={columnsFourrage(PETITES_CHEVRETTES_FOURRAGE)}
                  onChange={(newData) =>
                    onChange(PETITES_CHEVRETTES_FOURRAGE, newData)
                  }
                  onItemAdd={() =>
                    onChange(PETITES_CHEVRETTES_FOURRAGE, [
                      ...petitesChevrettesfourrages,
                      {
                        typeAnimal: 2,
                        typeAliment: 1,
                        msPourcent: null,
                        prixAchatEuroHT: null,
                        quantiteLot1: null,
                        quantiteLot2: null,
                        quantiteLot3: null,
                        quantiteLot4: null,
                        quantiteLotTari: null,
                      },
                    ])
                  }
                  errors={errorsFouragesPC}
                  canEdit={canEdit}
                  disabled={!canEdit}
                  onRowClick={() => {
                    this.setState({
                      currentEditedGrid: PETITES_CHEVRETTES_FOURRAGE,
                    });
                  }}
                />
                <CustomGrid
                  title="Aliments simples, composés ou minéraux (Qté en g)"
                  className="grid-aliment-chevrette"
                  canAdd
                  dataGrid={petitesChevrettesAlimentsSimples}
                  fieldId="dmAlimentationId"
                  columns={columnsAlimentsSimples(
                    PETITES_CHEVRETTES_ALIMENTS_SIMPLES
                  )}
                  onChange={(newData) =>
                    onChange(PETITES_CHEVRETTES_ALIMENTS_SIMPLES, newData)
                  }
                  onItemAdd={() =>
                    onChange(PETITES_CHEVRETTES_ALIMENTS_SIMPLES, [
                      ...petitesChevrettesAlimentsSimples,
                      {
                        typeAnimal: 2,
                        typeAliment: 2,
                        msPourcent: null,
                        prixAchatEuroHT: null,
                        quantiteLot1: null,
                        quantiteLot2: null,
                        quantiteLot3: null,
                        quantiteLot4: null,
                        quantiteLotTari: null,
                      },
                    ])
                  }
                  errors={errorsAlimentsSimplesPC}
                  canEdit={canEdit}
                  disabled={!canEdit}
                  onRowClick={() => {
                    this.setState({
                      currentEditedGrid: PETITES_CHEVRETTES_ALIMENTS_SIMPLES,
                    });
                  }}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
          <PanelBar
            className={`${canEdit && "panelbar-editable"}`}
            style={{ width: "50%" }}
          >
            <PanelBarItem title="Grandes chevrettes" expanded>
              <div className="k-m-4">
                <CustomGrid
                  title="Fourrages (Qté en Kg)"
                  className="grid-fourrage-chevrette k-mb-4"
                  canAdd
                  dataGrid={grandesChevrettesFourrages}
                  fieldId="dmAlimentationId"
                  columns={columnsFourrage(GRANDES_CHEVRETTES_FOURRAGE)}
                  onChange={(newData) =>
                    onChange(GRANDES_CHEVRETTES_FOURRAGE, newData)
                  }
                  onItemAdd={() =>
                    onChange(GRANDES_CHEVRETTES_FOURRAGE, [
                      ...grandesChevrettesFourrages,
                      {
                        typeAnimal: 3,
                        typeAliment: 1,
                        msPourcent: null,
                        prixAchatEuroHT: null,
                        quantiteLot1: null,
                        quantiteLot2: null,
                        quantiteLot3: null,
                        quantiteLot4: null,
                        quantiteLotTari: null,
                      },
                    ])
                  }
                  errors={errorsFouragesGC}
                  canEdit={canEdit}
                  disabled={!canEdit}
                  onRowClick={() => {
                    this.setState({
                      currentEditedGrid: GRANDES_CHEVRETTES_FOURRAGE,
                    });
                  }}
                />
                <CustomGrid
                  title="Aliments simples, composés ou minéraux (Qté en g)"
                  className="grid-aliment-chevrette"
                  canAdd
                  dataGrid={grandesChevrettesAlimentsSimples}
                  fieldId="dmAlimentationId"
                  columns={columnsAlimentsSimples(
                    GRANDES_CHEVRETTES_ALIMENTS_SIMPLES
                  )}
                  onChange={(newData) =>
                    onChange(GRANDES_CHEVRETTES_ALIMENTS_SIMPLES, newData)
                  }
                  onItemAdd={() =>
                    onChange(GRANDES_CHEVRETTES_ALIMENTS_SIMPLES, [
                      ...grandesChevrettesAlimentsSimples,
                      {
                        typeAnimal: 3,
                        typeAliment: 2,
                        msPourcent: null,
                        prixAchatEuroHT: null,
                        quantiteLot1: null,
                        quantiteLot2: null,
                        quantiteLot3: null,
                        quantiteLot4: null,
                        quantiteLotTari: null,
                      },
                    ])
                  }
                  errors={errorsAlimentsSimplesGC}
                  canEdit={canEdit}
                  disabled={!canEdit}
                  onRowClick={() => {
                    this.setState({
                      currentEditedGrid: GRANDES_CHEVRETTES_ALIMENTS_SIMPLES,
                    });
                  }}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
      </div>
    );
  }
}

AlimChevrettes.propTypes = {
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  alimentsSimples: PropTypes.array.isRequired,
  alimentsFourrages: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  onAddAliment: PropTypes.func.isRequired,
};

AlimChevrettes.defaultProps = {
  canEdit: false,
};

export default AlimChevrettes;
