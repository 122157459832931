import {
  Button,
  ButtonGroup,
  Toolbar,
  ToolbarItem,
  ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { useQuery, useQueryClient } from "@tanstack/react-query";
// https://github.com/eKoopmans/html2pdf.js/issues/570
import html2pdf from "html2pdf.js/dist/html2pdf.min.js";
import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarController,
  LineController,
  Colors,
} from "chart.js";
import { Header } from "../../shared/ReportViewer/Header";
import { clamp, unique } from "../../shared/ReportViewer/utils";
import { FluxIndividuel } from "./FluxIndividuel";
import { FluxBilan } from "./FluxBilan";
import { ChartProductionLaitiere } from "./ChartProductionLaitiere";
import { ChartMarge } from "./ChartMarge";
import { MiniChartMargeStart } from "./MiniChartMargeStart";
import { MiniChartMargeEnd } from "./MiniChartMargeEnd";
import { FluxGroupeNumExpl } from "./FluxGroupeNumExpl";
import dataSource from "../../../dataSource/dataSource";
import XLSX from "xlsx";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarController,
  // @ts-ignore
  Colors
);

// Permet de rendre net les graphiques quand on les exporte en pdf
window.devicePixelRatio = 2;


const { PUBLIC_URL } = process.env;
const ZOOM_STEP = 5;

/**
 * @template T
 * @param {Record<string, T>} o
 * @returns {Record<string,string>}
 */
function convertPropertiesToString(o) {
  /** @type {Record<string,string>} */
  let result = {};
  Object.keys(o).forEach((key) => {
    result[key] = String(o[key]);
  });

  return result;
}

/**
 * @typedef {Object} ReportParameter paramètre pour l'appel d'API
 * @property {number} domaine
 * @property {number} codeEleveur
 * @property {number} annee
 * @property {number} mois
 */

/**
 * @typedef {Object} FluxGlobal
 * @property {string} Nom
 * @property {string} NumAdh
 * @property {string} Groupe
 * @property {string} Laiterie
 * @property {string} MoisTraite
 * @property {string} Copyright
 * @property {string} Titre1
 * @property {string} Titre2
 * @property {string} Titre3
 * @property {string} Titre4
 *
 * @typedef {Object} FluxIndividuelPdf
 * @property {string} LibLigne
 * @property {boolean} TypeLigne
 * @property {string} Unite
 * @property {string} LibColonne
 * @property {number} Valeur
 *
 * @typedef {Object} FluxBilanPdf
 * @property {string} LibLigne
 * @property {string} Valeur1
 * @property {string} Valeur2
 * @property {string} Valeur3
 * @property {string} Valeur4
 *
 * @typedef {Object} Dataset
 * @property {string} Date
 * @property {number} Valeur
 *
 * @typedef {Object} FluxGroupeNumExpl
 * @property {string} Libelle1
 * @property {string} Libelle2
 * @property {string} NumExploit
 * @property {boolean} Highlight
 * @property {number} Valeur
 *
 * @typedef {Object} Report
 * @property {Array<FluxGlobal>} ListFluxGlobal
 * @property {Array<FluxIndividuelPdf>} ListLaitJourChevreTraite
 * @property {Array<FluxIndividuelPdf>} ListIngestionTotale
 * @property {Array<FluxIndividuelPdf>} ListCoutAlimentaireTotal
 * @property {Array<FluxIndividuelPdf>} ListPrixDuLait
 * @property {Array<FluxIndividuelPdf>} ListMargeAlimentaire
 *
 * @property {Array<FluxIndividuelPdf>} ListLaitJourChevreTraiteCumul
 * @property {Array<FluxIndividuelPdf>} ListIngestionTotaleCumul
 * @property {Array<FluxIndividuelPdf>} ListCoutAlimentaireTotalCumul
 * @property {Array<FluxIndividuelPdf>} ListPrixDuLaitCumul
 * @property {Array<FluxIndividuelPdf>} ListMargeAlimentaireCumul
 *
 * @property {Array<FluxBilanPdf>} ListFluxBilanAliment
 * @property {Array<FluxBilanPdf>} ListFluxBilanChevreaux
 * @property {Array<FluxBilanPdf>} ListFluxBilanReformes
 *
 * @property {Array<Dataset>} ListTButyreuxGraph
 * @property {Array<Dataset>} ListTProteiqueGraph
 * @property {Array<Dataset>} ListTraiteGraph
 * @property {Array<Dataset>} ListProdLaitGraph
 * @property {Array<Dataset>} ListFourragesGraph
 * @property {Array<Dataset>} ListCompIndivGraph
 * @property {Array<Dataset>} ListMCAGraph
 *
 * @property {Array<FluxGroupeNumExpl>} ListFluxGroupeNumExpl
 * @property {Array<FluxGroupeNumExpl>} ListFluxGroupeCout
 * @property {Array<FluxGroupeNumExpl>} ListFluxGroupePaieDuMois
 */

/**
 * int domaine, int codeEleveur, int mois, int annee
 * @param {ReportParameter} params
 * @returns {Promise<Report>}
 */
async function fetchReport(params) {
  const stringParameters = convertPropertiesToString(params);
  const urlParams = new URLSearchParams(stringParameters);
  const response = await dataSource.fetchPdf(urlParams);
  
  return response;
}

/**
 *
 * @param {FluxGlobal | undefined} data
 */
async function printReport(data) {
  let element = document.getElementById("the-pdf");
  const opt = {
    filename: `Rapport Données Mensuelles Résultats ${data?.Nom}.pdf`,
    html2canvas: { scale: 2 },
    jsPDF: { orientation: "portrait" },
  };

  html2pdf()
    .from(element)
    .set(opt)
    .toPdf()
    .get("pdf")
    .then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(150);
        pdf.text(
          pdf.internal.pageSize.getWidth() - 30,
          pdf.internal.pageSize.getHeight() - 10,
          "Page " + i + "/" + totalPages
        );
        pdf.text(
          pdf.internal.pageSize.getWidth() / 2 - 20,
          pdf.internal.pageSize.getHeight() - 10,
          data?.Copyright
        );
        const img = new Image();
        img.src = `${PUBLIC_URL}/images/logo_adm.png`;
        pdf.addImage(img, "PNG", 5, pdf.internal.pageSize.getHeight() - 17, 15, 15*557/675);
      }
    })
    .save();
}

/**
 * @param {ReportParameter} params
 */
const useReport = (params) => {
  const queryClient = useQueryClient();
  return useQuery(["donneesMensuelles", params], async () => {
    const report = await fetchReport(params);
    queryClient.setQueryData(
      ["Header", params],
      report.ListFluxGlobal[0]
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Dates", params],
      unique(report.ListLaitJourChevreTraite, "LibColonne")
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListLaitJourChevreTraite", params],
      report.ListLaitJourChevreTraite
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListIngestionTotale", params],
      report.ListIngestionTotale
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListCoutAlimentaireTotal", params],
      report.ListCoutAlimentaireTotal
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListPrixDuLait", params],
      report.ListPrixDuLait
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListMargeAlimentaire", params],
      report.ListMargeAlimentaire
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListLaitJourChevreTraiteCumul", params],
      report.ListLaitJourChevreTraiteCumul
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListIngestionTotaleCumul", params],
      report.ListIngestionTotaleCumul
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListCoutAlimentaireTotalCumul", params],
      report.ListCoutAlimentaireTotalCumul
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListPrixDuLaitCumul", params],
      report.ListPrixDuLaitCumul
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListMargeAlimentaireCumul", params],
      report.ListMargeAlimentaireCumul
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListFluxBilanAliment", params],
      report.ListFluxBilanAliment
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListFluxBilanChevreaux", params],
      report.ListFluxBilanChevreaux
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Row", "ListFluxBilanReformes", params],
      report.ListFluxBilanReformes
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Chart", "ListTButyreuxGraph", params],
      report.ListTButyreuxGraph
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Chart", "ListTProteiqueGraph", params],
      report.ListTProteiqueGraph
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Chart", "ListTraiteGraph", params],
      report.ListTraiteGraph
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Chart", "ListProdLaitGraph", params],
      report.ListProdLaitGraph
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Chart", "ListFourragesGraph", params],
      report.ListFourragesGraph
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Chart", "ListCompIndivGraph", params],
      report.ListCompIndivGraph
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "Chart", "ListMCAGraph", params],
      report.ListMCAGraph
    );
    queryClient.setQueryData(
      [
        "donneesMensuelles",
        "RowGroupeNumExpl",
        "ListFluxGroupeNumExpl",
        params,
      ],
      report.ListFluxGroupeNumExpl
    );
    queryClient.setQueryData(
      ["donneesMensuelles", "RowGroupeNumExpl", "ListFluxGroupeCout", params],
      report.ListFluxGroupeCout
    );
    queryClient.setQueryData(
      [
        "donneesMensuelles",
        "RowGroupeNumExpl",
        "ListFluxGroupePaieDuMois",
        params,
      ],
      report.ListFluxGroupePaieDuMois
    );

    return report;
  });
};

/**
 * ReportViewer est une pop-up pour afficher _et_ télécharger le fichier pdf
 * et le fichier Excel pour un éleveur et un mois défini
 * @typedef {Object} Props
 * @property {string} title titre de la pop-up
 * @property {() => void} onClose callback appelé lors de la fermeture
 * @property {ReportParameter} params paramètre pour l'appel d'API
 * @param {Props} props {@link Props}
 */
const ReportViewer = ({ title, onClose, params }) => {
  const [zoom, setZoom] = useState(100);
  // TODO gérer les erreurs
  const { data: report, isLoading } = useReport(params);
  const queryClient = useQueryClient();
  const handleOnItemClick = () => {
    const data = /** @type {FluxGlobal | undefined} */ (
      queryClient.getQueryData(["Header", params])
    );

    printReport(data);
  };

  const handleOnXLSXClick = ()=>{
    const fileName = queryClient.getQueryData(["Header", params]).Nom;
    const extension = 'xlsx';

    // Eleveur
    const tableWrapperCombined = createNewTable('.table-wrapper', 'td')

    const eleveur = XLSX.utils.table_to_book(tableWrapperCombined, {sheet: "elevage"});
    XLSX.write(eleveur, { bookType: "xlsx", bookSST: true, type: 'base64' });
    XLSX.writeFile(eleveur, `RapportDonneesMensuellesResultats_${fileName}_eleveur.${extension}`);

    // Groupe
    const tableThirdCombined = createNewTable(".third", 'th');

    const groupe = XLSX.utils.table_to_book(tableThirdCombined, { sheet: "groupe" });
    XLSX.write(groupe, { bookType: "xlsx", bookSST: true, type: 'base64' });
    XLSX.writeFile(groupe, `RapportDonneesMensuellesResultats_${fileName}_groupe.${extension}`);

  };

  /**
   * Créer une table générale contenant la fusion de plusieurs balises <tables/> DOM
   * @param {*} table Element DOM à fusionner
   * @param {*} tableHeader Type de table header attendus (th ou td)
   * @returns balise <table>
   */
  const createNewTable = (table, tableHeader) => {
    let tableToCombine = document.querySelector(`.the-pdf ${table}`);
    let groupData = tableToCombine.getElementsByTagName('table');

    let combinedTable = document.createElement('table'); // Création de la table combinée
    let thead = document.createElement('thead');
    let theadtrMainHead = document.createElement('tr');
    let theadtrSubHead = document.createElement('tr');
    thead.appendChild(theadtrMainHead);
    thead.appendChild(theadtrSubHead);
    combinedTable.appendChild(thead);

    for (let i = 0; i < groupData.length; i++) {
      // Fusionner les entêtes
      let heads = groupData[i].getElementsByTagName('thead');
      for (let j = 0; j < heads.length; j++) {
        const headTr = heads[j].getElementsByTagName('tr');
        const mainHead = headTr[0].getElementsByTagName(tableHeader);
        const subHead = headTr[1].getElementsByTagName(tableHeader);

        // Première balise <tr/>
        for (let k = 0; k < mainHead.length; k++) {
          theadtrMainHead.appendChild(mainHead[k].cloneNode(true));
        }

        // Seconde balise <tr/>
        for (let k = 0; k < subHead.length; k++) {
          theadtrSubHead.appendChild(subHead[k].cloneNode(true));
        }
      }

      // Fusionne les corps du tableau
      let bodies = groupData[i].getElementsByTagName('tbody');
      for (let j = 0; j < bodies.length; j++) {
        let tbody = bodies[j];

        // Création <tbody>
        let combinedTbody;
        if (combinedTable.getElementsByTagName('tbody').length <= j) {
          combinedTbody = document.createElement('tbody');
          combinedTable.appendChild(combinedTbody);
        } else {
          combinedTbody = combinedTable.getElementsByTagName('tbody')[j];
        }

        // Contenu <tbody>
        let bodyTr = tbody.getElementsByTagName('tr');
        for (let k = 0; k < bodyTr.length; k++) {
          // Création <tr>
          if (combinedTbody.children.length <= k) {
            combinedTbody.appendChild(document.createElement('tr'));
          }

          // Contenu <tr>
          let bodyTd = bodyTr[k].getElementsByTagName('td');
          for (let l = 0; l < bodyTd.length; l++) {
            combinedTbody.children[k].appendChild(bodyTd[l].cloneNode(true)); // Création / Contneu <td>
          }
        }
      }
    }
    return combinedTable;
}


  return (
    <Dialog
      title={title}
      onClose={onClose}
      contentStyle={{
        height: "80vh",
        width: "75vw",
        padding: 0,
      }}
    >
      <div
        className="k-d-flex-col"
        style={{ overflow: "auto", height: "100%" }}
      >
        <Toolbar className="k-flex-shrink-0">
          <ToolbarItem>Zoom : {zoom}%</ToolbarItem>
          <ToolbarItem>
            <ButtonGroup>
              <Button
                icon="zoom-in"
                title="Zoom +"
                onClick={() => {
                  setZoom((zoom) => clamp(zoom + ZOOM_STEP, 50, 150));
                }}
              />
              <Button
                icon="zoom-out"
                title="Zoom -"
                onClick={() => {
                  setZoom((zoom) => clamp(zoom - ZOOM_STEP, 50, 150));
                }}
              />
              <Button
                onClick={() => {
                  setZoom(100);
                }}
              >
                Ré-initialiser
              </Button>
            </ButtonGroup>
          </ToolbarItem>
          <ToolbarSpacer />
          <ToolbarItem>
            <Button disabled={report === undefined} onClick={handleOnXLSXClick}>
              XLSX
            </Button>
          </ToolbarItem>
          <ToolbarSpacer />
          <ToolbarItem>
            <Button disabled={report === undefined} onClick={handleOnItemClick}>
              Télécharger le PDF
            </Button>
          </ToolbarItem>
        </Toolbar>
        <div className="flex-fill k-overflow-auto k-p-5 bg-white">
          {isLoading && <Loader />}
          {!isLoading && report !== undefined && (
            <div
              id="the-pdf"
              className="the-pdf"
              style={
                /** @type {React.CSSProperties} */ ({
                  "--zoom": zoom,
                })
              }
            >
              <Header
                params={params}
                style={
                  /** @type {React.CSSProperties} */ ({
                    "--cell-height": "5mm",
                  })
                }
                supertitle="Vos résultats"
              />
              <FluxIndividuel report={report} params={params} />
              <FluxBilan params={params} />
              <Header
                params={params}
                style={
                  /** @type {React.CSSProperties} */ ({
                    "--cell-height": "5mm",
                  })
                }
              />
              <ChartProductionLaitiere params={params} />
              <ChartMarge params={params} />
              <div className="mini-chart">
                <div className="mini-chart-width">
                  <MiniChartMargeStart params={params} />
                </div>
                <div className="mini-chart-width">
                  <MiniChartMargeEnd params={params} />
                </div>
              </div>
              <Header
                params={params}
                style={
                  /** @type {React.CSSProperties} */ ({
                    "--cell-height": "5mm",
                  })
                }
              />
              <FluxGroupeNumExpl params={params} />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ReportViewer;
